import './css/Header.css';
import { BsFillMoonFill } from 'react-icons/bs';
import { BsSunFill } from 'react-icons/bs';

export default function Header(props) {
   const { theme, setTheme } = props;
   function ToggleTheme() {
      if (theme === 'light') {
         setTheme('dark');
      } else {
         setTheme('light');
      }
   }
   return (
      <header>
         <div className="logo">
            <span>Task Management</span>
         </div>
         <div className="theme-switch">
            <span className="mode" onClick={ToggleTheme}>
               {theme === 'light' ? <BsFillMoonFill /> : <BsSunFill/>}
            </span>
         </div>
      </header>
   );
}
