import './css/Item.css';
import { CiCircleRemove } from 'react-icons/ci';
import { CiEdit } from 'react-icons/ci';

export default function Item(props) {
   const { data, deleteTask, editTask } = props;
   return (
      <div className="list-item">
         <p className="title">{data.title}</p>
         <div className="btn-div">
            <CiCircleRemove
               className="btn"
               onClick={() => deleteTask(data.id)}
            />
            <CiEdit className="btn" onClick={() => editTask(data.id)} />
         </div>
      </div>
   );
}
